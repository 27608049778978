@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
$small-screen: "(min-width:#{$small}px)";
$medium-screen: "(min-width:#{$medium}px)";
$large-screen: "(min-width:#{$large-up}px)";

[data-sparta-container] {
  .spa-spin--margin-auto {
    margin: 0 auto;
  }
  .spa-spinner--remove-shadow {
    box-shadow: none;
  }
  .overlay-wrapper {
    z-index: $layer-top;
  }
  .spa-spin-page-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: $layer-top;
  }
  .spa-spin-error-title {
    display: inline-block;
  }
  .spa-spin {
    z-index: $layer-page;
    width: 100%;
    text-align: center;
  }

  .spa-spin-wrapper {
    .spa-spin-content-wrapper {
      width: 100%;
      display: block;
      padding-top: $spacing-16;

      .spa-spin-msg-wrapper {
        margin-bottom: $spacing-12;
        padding-left: $spacing-12;
        padding-right: $spacing-12;
      }


      .spartaMessage  {
        text-align: left;
        margin: 0;
        padding: 0;

        .spartaMessage-content {
          padding-bottom: $spacing-8;
        }

        .spartaMessage-title,
        .spartaMessage-text {
          color: $color-regal-one;
          font-weight: $bold-weight;
        }

        .spartaMessage-title + .spartaMessage-text {
          font-weight: $regular-weight;
          color: $color-black;
        }
      }
    }

    &[data-spinner-skin] {
      border: 2px solid transparent;
      border-radius: $spacing-12;
      padding-bottom: $spacing-24;

      &.spa-spinner--show-bdr {
        border-color: $color-regal-one;
      }

      @media screen and #{$small-screen} {
        .spa-spin-cancel-msg,
        .spa-spin-error-msg {
          padding-left: $spacing-12;
          padding-right: $spacing-12;
        }
      }

      @media screen and #{$large-screen} {
        .spa-spin-cancel-msg,
        .spa-spin-error-msg {
          padding-left: $spacing-16;
          padding-right: $spacing-16;
        }
      }
    }

    .spa-icon-loading {
      width: $spacing-48;
      height: $spacing-48;
      &:before {
        background-image: icon(loader-72, (color: $color-royal-one));
      }
    }

    .spa-spin-cancel {
      &-title {
        font-weight: bold;
      }

      &-wrapper {
        button {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .spa-spin-error {
      &-title {
        font-weight: bold;
        margin-bottom: $spacing-4;
      }

      &-text {
        display: block;
      }
    }

    .icon-warning-red {
      background-image: icon(warning, (color: $color-regal-one));
      border-color: transparent;
      width: 24px;
      height: 24px;
    }
  }

  .spa-spin--hidden {
    display: none;
  }

  .spa-spin--invisible {
    visibility: hidden;
  }

  @media screen and #{$small-screen} {
    .spa-spin-msg-wrapper {
      padding-left: $spacing-40;
      padding-right: $spacing-40;
    }
    .spa-spin-error-icon {
      display: block;
      padding-right: 5px;
    }
    .spa-spin-error-msg {
      padding-left: $spacing-40;
      padding-right: $spacing-40;
      text-align: center;
    } 
    .spa-spin-cancel-title,.spa-spin-cancel-text {
      text-align: center;
    }
    .spa-spin-error-text {
      text-align: center;
      margin-bottom: $spacing-12;
    }

    .spa-spin-cancel-msg {
      padding-left: $spacing-40;
      padding-right: $spacing-40;
    }
  }

  @media screen and #{$medium-screen} {
    .spa-spin-page-wrapper {
      width: 50%;
    }
  }

  @media screen and #{$large-screen} {
    .spa-spin-page-wrapper {
      width: 25%;
    }

    .spa-spin-msg-wrapper {
      padding-left: $spacing-24;
      padding-right: $spacing-24;
    }

    .spa-spin-error-msg {
      padding-left: $spacing-24;
      padding-right: $spacing-24;
    }

    .spa-spin-cancel-msg {
      padding-left: $spacing-24;
      padding-right: $spacing-24;
    }
  }
}